.btn {
    background-color: #be1e2d;
    color: $color-white;
    border-radius: $round-corner-radius;
    padding: 10px;
    font-size: $font-size-26;
    font-weight: 600;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
