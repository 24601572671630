.bursts {
    width: 100%;
    padding: 65px 10px;
    background: #000;
    .bursts-container {
        @extend .flex;
        @extend .justify-center;
        @extend .flex-wrap;
        .burst-wrapper{
          padding: 15px;
          margin: 20px 0;
        }
        .burst {
          //flex: 1;
          position: relative;
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }

          .overlay {
            opacity: 0;
            transition: all 0.7s ease-out; // OUT effect
          }
        }
        p {
          text-align: center;
          font-size: $font-size-36;
          color: #fff;
          font-weight: 500;
          line-height: 1.2;
          span {

          }
          transition: all 0.2s ease-out; // OUT effect
        }
        a:hover {
          .burst {
              .overlay {
                  opacity: 1;
                  transition: all 0.3s ease; // IN effect
              }

          }
          p {
              color: #be1e2d;
              transition: all 0.3s ease; // IN effect

          }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}
