// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


h1, h2, h3, h4, h5 {
    font-family: $font-family-1;
}

p {
    font-family: $font-family-2;
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/



section.section-01-accueil {
    background-color: $color-white;
    background-image: url(../images/accueil_section01_bg_top.jpg);
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px 0;
    .section-01-logo {
        margin: auto;
    }
    div.txt {
        padding-left: 35px;
        h3 {
            color: $color-black;
            font-size: $font-size-38;
            line-height: 1.2;
            font-weight: 600;
        }
        p {
            font-weight: 500;
            line-height: 1.3;
            font-size: $font-size-20;
            color: #333333;
            text-align: justify;
        }
        .qc {
            padding-top: 30px;
            h4 {
                font-family: $font-family-3;
                color: #00a3d9;
                font-weight: 500;
                font-size: $font-size-36;
                padding-bottom: 5px;
            }
            h2 {
                font-family: $font-family-3;
                color: #00a3d9;
                font-weight: 600;
                font-size: $font-size-60;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        .px4.my4 {
            width: 85%;
            margin: 20px auto;
            .img-responsive {
                margin: auto;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

section.parallax {
    padding: 150px 10px 130px 10px;
    background-image: url(../images/accueil_section02_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    div.txt {
        width: 100%;
        max-width: 1200px;
        h4 {
            color: $color-white;
            text-align: center;
            font-size: $font-size-50;
            font-weight: 500;
        }
        a.link {
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35%;
            margin: 40px auto 0 auto;
            padding: 10px;
            color: #fff;
            font-weight: 500;
            font-size: $font-size-32;
            transition: 0.3s;
            &:hover {
                transition: 0.3s;
                background: #ffffff;
                color: #000000;
            }
        }
    }
}

section.section-text-image {
    div.txt {
        background: #be1e2d;
        width: 50%;
        padding: 100px 5%;
    }
    div.img-side {
      background-image:url(../images/accueil_section04_pho01.jpg);
      background-position: left bottom;
      background-size: cover;
      width: 50%;
    }

    h3 {
        color: $color-white;
        font-size: $font-size-36;
        font-weight: 600;
    }
    ul {
        list-style-image: url(../images/accueil_section04_point_blanc.jpg);
        padding-left: 50px;
        li {
            color: $color-white;
            line-height: 1.5;
            padding-bottom: 5px;
            padding-left: 40px;
            font-weight: 500;
            text-transform: inherit;
            font-family: $font-family-3;
            font-size: $font-size-26;
        }
    }
    @media screen and (max-width: $size-sm-max){
      div.txt {
          width: 100%;
          padding: 30px 10px 100px 10px;
      }
      div.img-side {
        width: 100%;
        height: 400px;
      }
    }
}

section.section-image-text {
    padding: 75px;
    .left-side {
        display: flex;align-items: center;
        width: 40%;
        padding-left: 10%;
        .img {
            width: 44%;
            a.pdfLink {
                h3 {
                    text-transform: initial;
                    font-weight: 600;
                    font-size: $font-size-36;
                    color: #be1e2d;
                    text-align: center;
                    padding-top: 5px;
                }
                img.img-responsive {
                    margin: auto;
                }
                img.img-responsive.hover {
                    display: none;
                }
                &:hover {
                    h3 {
                        color: #000;
                    }
                    img.img-responsive.hover {
                        display: block;
                    }
                    img.img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
    .center, .right-side {
        width: 30%;
        h3 {
            color: #000;
            font-weight: 600;
            font-size: 36px;
            padding-bottom: 15px;
        }
        ul {
            list-style-image: url(../images/accueil_section05_point_noir.jpg);
            padding-left: 50px;
            li {
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 40px;
                font-weight: 500;
                text-transform: initial;
                font-size: $font-size-26;
                font-family: $font-family-3;
            }
        }
    }
}

section.section-01-accueil.temoignages  {
    h3.pb4.mt12 {
        color: #000;
        font-weight: 600;
        font-size: $font-size-42;
        padding-left: 20px;
        padding-bottom: 50px;
    }
    .main-content {
        position: relative;
        h3.name {
            color: #be1e2d;
            font-size: $font-size-38;
            line-height: 1.2;
            font-weight: 600;
            padding-bottom: 10px;
        }
        h4 {
            color: #000;
            font-weight: 600;
            font-size: $font-size-28;
            font-style: italic;
            line-height: 1.5;
        }
        .owl-theme {
            .custom-nav {
                position: absolute;
                top: 20%;
                left: 0;
                right: 0;

                .owl-prev, .owl-next {
                    position: absolute;
                    height: 100px;
                    color: inherit;
                    background: none;
                    border: none;
                    z-index: 100;

                    i {
                        font-size: 2.5rem;
                        color: #be1e2d;
                    }
                }
            }
        }
        .arrow.prev.next-vedette {
            position: absolute;
            left: -10%;
            top: 20%;
        }
        .arrow.next.prev-vedette {
            position: absolute;
            right: -10%;
            top: 20%;
        }
        .arrow {
            .img-responsive.hover {
                display: none;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* EXCAVATION
*******************************************************************************/

section.section-01-accueil.excavatrices {
    ul {
        list-style-image: url(../images/point_noir.jpg);
        padding-left: 50px;
        li {
            color: $color-black;
            line-height: 1.5;
            padding-bottom: 5px;
            padding-left: 40px;
            font-weight: 500;
            text-transform: initial;
            font-size: $font-size-26;
            font-family: $font-family-3;
        }
    }
    .bouton {
        clear: both;
        width: 40%;
        background: #000;
        margin: 0 auto 50px auto;
        text-align: center;
        padding: 10px;
        font-size: $font-size-38;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        transition: 0.3s;
        &:hover {
            background: #be1e2d;
            transition: 0.3s;
        }
    }
}

#section-equipement {
    .bloc {
        background: #be1e2d;
        margin-bottom: 25px;
        padding: 50px 0;
        h3 {
            font-size: $font-size-42;
            font-weight: 600;
            color: #fff;
            text-transform: initial;
            padding-bottom: 25px;
        }
        .img-text {
            display: flex;
            .left-side {
                width: 46%;
                margin-right: 3%;
                p {
                    font-size: $font-size-22;
                    line-height: 1.5;
                    margin: 0;
                    color: #fff;
                    font-family: $font-family-2;
                    padding-bottom: 35px;
                }
            }
            ul {
                list-style-image: url(../images/point_blanc.jpg);
                padding-left: 50px;
                li {
                    color: $color-white;
                    line-height: 1.5;
                    padding-bottom: 10px;
                    padding-left: 40px;
                    font-weight: 500;
                    text-transform: initial;
                    font-size: $font-size-24;
                    font-family: $font-family-3;
                }
            }
            .logo {
                display: flex;
                justify-content: center;
                padding-top: 10%;
            }
            h4 {
                color: #fff;
                font-weight: 500;
                font-size: $font-size-24;
            }
        .img-responsive.logo {
            padding: 0 0 35px 0;
        }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .section-images.zoom-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .bloc {
            width: 20%;
            padding: 15px 15px 0 15px;
            margin: 0;
            .img-responsive {
                margin: auto;
                padding-bottom: 5px;
            }
            h4 {
                color: #fff;
                font-weight: 500;
                font-size: $font-size-24;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}


/*******************************************************************************
* FAQ
*******************************************************************************/

section.section-01-accueil.faq {
    padding: 70px 0;
    .title {
        padding: 0 15% 50px 15%;
        h3 {
            color: $color-black;
            font-size: $font-size-38;
            line-height: 1.2;
            font-weight: 600;
        }
    }
    .bloc {
        .section-question {
            display: flex;
            padding: 35px 15%;
            .left-side {
                background: #000;
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 20px;
                padding-right: 3px;
                h2 {
                    font-family: $font-family-1;
                    font-weight: 500;
                    font-size: $font-size-100;
                    color: #fff;
                }
            }
            .right-side {
                width: 80%;
                margin-left: 3%;
                h4 {
                    font-weight: 500;
                    font-size: $font-size-24;
                    font-family: $font-family-3;
                    color: #000;
                }
            }
        }
        .section-answer {
            display: flex;
            background: #000;
            padding: 35px 15%;
            .left-side {
                background: #fff;
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 20px;
                padding-right: 10px;
                margin-right: 3%;
                h2 {
                    font-family: $font-family-1;
                    font-weight: 500;
                    font-size: $font-size-100;
                    color: #be1e2d;
                }
            }
            .right-side {
                h4, p {
                    font-weight: 500;
                    font-size: $font-size-24;
                    font-family: $font-family-3;
                    color: #fff;
                }
                p {
                    margin: 0;
                    line-height: 1.1;
                }
            }
        }
    }
}

/*******************************************************************************
* GALERIE
*******************************************************************************/
section.section-menu-filter {
    padding-bottom: 25px;
    button {
        //text-decoration: underline;
        text-align: left;

        color: $color-white;
        font-weight: 700;
        width: 100%;
        font-size: $font-size-24;
        padding: 10px 20px;
        text-transform: uppercase;
        background-color: #be1e2d;
        border-bottom-color: #be1e2d;
        border-top-color: #be1e2d;
        border-left-color: #be1e2d;
        border-right-color: #be1e2d;
        border-bottom-style: none;
        border-top-style: none;
        border-left-style: none;
        border-right-style: none;
        @extend .font-1-normal;
        &:hover, &:focus, &.active {
            color: $color-black;
            transition: .4s;
        }
    }
}


section.gallerie-products {
    .item {
        margin-bottom: 25px;
        h3 {
            padding: 10px 0;
            color: $color-black;
            font-size: $font-size-22;
        }
        img {
            width: 100%;
        }
        iframe {
            width: 100%!important;
            height: 300px;
            @media screen and (max-width: $size-lg-min){
              height: 200px;
              @media screen and (max-width: $size-xs-max){
                height: calc(100vw * 180 / 320);
              }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

section.section-01-accueil.coordonnees {
    .logo-text {
        display: flex;
        .logo {
            width: 20%;
        }
        .text {
            h4 {
                font-family: $font-family-3;
                font-weight: 500;
                font-size: $font-size-26;
                color: $color-black;
                line-height: 1.4;
            }
        }
        .heures-jours {
            display: flex;
            width: 100%;
            h4 {
                font-family: $font-family-3;
                font-weight: 500;
                font-size: $font-size-26;
                color: $color-black;
                line-height: 1.4;
            }
            .jours {
                width: 60%;
            }
        }
    }
    .logo-reseau {
        display: flex;
        a.linkFacebook {
            display: block;
            margin-top: 40px;
            .img-responsive.hover {
                display: none;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
    .logo-text.tel {
        padding-top: 50px;
    }
}

#section-map-form {
    .right-side {
        h3 {
            color: $color-black;
            font-size: $font-size-38;
            font-weight: 600;
        }
    }
}

#section-team {
    h3 {
        color: $color-black;
        font-size: $font-size-38;
        font-weight: 600;
    }
    h3.poste {
        text-transform: initial;
    }
    .logo-text {
        display: flex;
        align-items: center;
        padding-top: 15px;
        .logo {
            width: 18%;
        }
        .text {
            h4 {
                font-family: $font-family-3;
                font-weight: 500;
                font-size: $font-size-26;
                color: $color-black;
            }
        }
    }
}

#section-paiement {
    padding-bottom: 75px;
    h3 {
        color: $color-black;
        font-size: $font-size-38;
        font-weight: 600;
    }
    h3.mode {
        text-transform: initial;
        font-size: $font-size-34;
    }
    h4 {
        font-family: $font-family-3;
        font-weight: 500;
        font-size: $font-size-24;
        color: $color-black;
        padding-top: 15px;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1550px) {

section.section-image-text .left-side {
    width: 32%;
    padding-left: 0;
}
section.section-image-text .left-side .img {
    width: 100%;
}
section.section-image-text .center, section.section-image-text .right-side {
    width: 34%;
}
}

@media screen and (max-width: 1500px) {

section.section-image-text .left-side {
    padding-left: 0%;
}
}

@media screen and (max-width: 1400px) {

#section-map-form .col-xs-12.col-sm-6 {
    width: 100%;
}
#section-map-form .col-xs-12.col-sm-6:last-child {
    padding-top: 50px;
}
}

@media screen and (max-width: $size-md-max) {

nav.module-menu .menu-navbar {
    height: 100px;
}
nav.module-menu {
    padding-top: 100px;
}
section.section-01-accueil div.txt .qc {
    padding-top: 85px;
}
section.section-image-text .left-side .img a.pdfLink h3 {
    font-size: 1.5rem;
}
#section-equipement .bloc .img-text .left-side p {
    padding-bottom: 50px;
}
#section-equipement .section-images .bloc {
    width: 30%;
    padding: 20px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar {
    height: 90px;
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -90px;
}
section.section-01-accueil div.txt .qc {
    padding-top: 0;
}
section.section-image-text {
    padding: 75px 25px;
}
section.section-01-accueil.temoignages {
    padding: 100px 10% !important;
}
section.section-image-text .center, section.section-image-text .right-side {
    width: 45%;
    padding: 25px;
}
section.section-01-accueil.coordonnees .logo-text .heures-jours h4 {
    font-size: 1.2rem;
}
section.section-01-accueil.coordonnees .logo-text .logo {
    width: 25%;
}
form .buttons {
    margin-left: 13%;
}
#section-equipement .bloc .img-text ul li {
    font-size: 1rem;
}
section.section-01-accueil.faq .title {
    padding: 0 5% 50px 5%;
}
section.section-01-accueil.faq .bloc .section-question, section.section-01-accueil.faq .bloc .section-answer {
    padding: 35px 5%;
}
}

@media screen and (max-width: $size-sm-max) {

section.section-image-text {
    padding: 50px 25px;
}
section.section-image-text .container-fluid.row.flex.justify-center.flex-wrap {
    flex-direction: column;
}
section.section-image-text .left-side {
    width: 100%;
    padding-bottom: 25px;
}
section.section-image-text .right-side {
    width: 100%;
}
section.section-image-text .center, section.section-image-text .right-side {
    width: 100%;
    padding: 0 0 25px 0;
}
#section-team h3 {
    font-size: 1.8rem;
}
#section-team .logo-text .logo {
    width: 25%;
}
.section-01-accueil.coordonnees .col-xs-12.col-sm-6.txt {
    width: 100%;
}
.section-01-accueil.coordonnees .col-xs-12.col-sm-6.txt:last-child {
    padding-top: 50px;
}
section.section-01-accueil.coordonnees .logo-text .logo {
    width: 17%;
}
section.section-01-accueil.coordonnees .logo-text .heures-jours h4 {
    font-size: 1.3rem;
}
section.section-01-accueil.coordonnees .logo-text .heures-jours .jours {
    width: 40%;
}
section.section-01-accueil.excavatrices ul li {
    padding-left: 15px;
}
section.section-01-accueil.faq .bloc .section-question .left-side, section.section-01-accueil.faq .bloc .section-answer .left-side {
    width: 125px;
    height: 125px;
}
section.section-01-accueil.faq .bloc .section-answer .right-side {
    width: 70%;
    margin-left: 3%;
}
section.section-01-accueil.faq .bloc .section-question .right-side {
    width: 70%;
    margin-left: 6%;
}
#section-equipement .bloc .img-text .logo {
    padding-top: 5%;
}
#section-equipement .bloc .img-text .left-side p {
    padding-bottom: 30px;
}
#section-equipement .section-images .bloc {
    width: 30%;
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 200px;
}
section.section-01-accueil .col-xs-12.txt {
    padding-left: 0;
}
section.section-01-accueil .col-xs-12.txt.last {
    padding-left: 15px;
}
section.parallax div.txt a.link {
    width: 70%;
}
section.parallax {
    padding: 100px 10px;
}
section.section-01-accueil.temoignages {
    padding: 50px 10% !important;
}
section.section-01-accueil.temoignages h3.pb4.mt12 {
    margin-top: 2rem;
}
section.section-01-accueil.temoignages .main-content .row.item {
    margin: 0;
}
section.section-01-accueil.temoignages .main-content h3.name {
    text-align: center;
}
section.section-01-accueil.temoignages .main-content .arrow.left, section.section-01-accueil.temoignages .main-content .arrow.right {
    display: none;
}
section.section-image-text .center h3, section.section-image-text .right-side h3 {
    font-size: 34px;
}
section.section-01-accueil.temoignages .main-content .arrow.prev.next-vedette, section.section-01-accueil.temoignages .main-content .arrow.next.prev-vedette {
    display: none;
}
section.section-01-accueil.coordonnees .logo-text .logo {
    width: 27%;
}
section.section-01-accueil.coordonnees .logo-text .heures-jours .jours {
    width: 60%;
}
#section-team .right-side {
    padding-top: 25px;
}
.module-formulaire .form-group .flex {
    flex-direction: column;
}
form label {
    width: 100%;
}
form .buttons {
    margin-left: 0;
    padding-left: 0;
}
#section-map-form .container {
    margin: 0;
    padding: 0;
}
#section-map-form .col-xs-12.flex.items-initial.flex-wrap.mt10 {
    padding: 0;
}
#section-map-form .right-side h3 {
    padding-bottom: 20px;
}
section.section-01-accueil.excavatrices .bouton {
    width: 65%;
}
#section-equipement .bloc .img-text {
    flex-direction: column;
}
#section-equipement .bloc .img-text .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
#section-equipement .bloc .img-text ul li {
    font-size: 1.2rem;
    padding-left: 15px;
}
section.section-01-accueil.faq .bloc .section-question .left-side, section.section-01-accueil.faq .bloc .section-answer .left-side {
    width: 100px;
    height: 100px;
}
section.section-01-accueil.faq .bloc .section-question .left-side {
    padding-bottom: 15px;
}
section.section-01-accueil.faq .bloc .section-answer .right-side {
    width: 65%;
}
section.section-01-accueil.faq .bloc .section-answer .left-side {
    padding-bottom: 10px;
    padding-right: 5px;
}
section.section-01-accueil.faq .bloc .section-question .right-side {
    width: 65%;
    margin-left: 7%;
}
#section-equipement .bloc .img-text .left-side p {
    padding-bottom: 15px;
}
#section-equipement .section-images .bloc {
    width: 100%;
}
}

@media screen and (max-width: 360px) {

section.section-01-accueil.coordonnees .logo.horaire {
    display: none;
}
section.section-01-accueil.faq .bloc .section-question .right-side {
    width: 60%;
    margin-left: 7%;
}
}

section.products-gallery {
    .page-title {
        color: $color-black;
        font-size: $font-size-38;

    }
}
$color-1: #be1e2d;

section.section-menu-filter {
    background-color: $color-1;
    padding: 0;
    margin-bottom: 30px;
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;;
    }

    ul.menu-filter {
          list-style: none;
          padding: 0;
          margin: 0;
          margin-right: auto;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          li {
              font-size: $font-size-24;
              padding: 0;
              margin: 0;
              line-height: 1;
              //border-right: 2px solid $color-white;
              &:last-child{
                  border-right: none;
              }
              button {
                  padding: 0 20px;
                  color: $color-white;
                  margin: 0;
                  font-weight: 600;
                  &:hover, &:focus {
                      color: $color-black;
                      transition: .4s;
                  }
              }
          }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            justify-content: center;
            flex-direction: column;
        }

        ul.menu-filter {
            flex-direction: column;
        }
    }
}
.masonry {
    //columns: 4;
    column-gap: 30px;
    columns: 3;
    @media (min-width: 1200px) {
    }
    @media (max-width: 992px) {
      columns: 2;
    }
    @media (max-width: 600px) {columns: 1;}

    .grid {
        display: inline-block;
        position: relative;
        margin-bottom: 30px;
        background-color: $color-white;
        border: 1px solid #000;
        &:before {
            //border-radius: 5px;
            content:'';
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            //background-color:rgba(0,0,0,.2);
        }
        img {
          width: 100%;
          //border-radius: 5px;
        }
        &__text {
            background-color: $color-white;
            &_title {
              font-size: $font-size-26;
              font-weight:500;
              font-family: $font-family-3;
              margin: 10px;
              color: #333333;
            }
            &_btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
                columns: 2;
            }
            &_prix {
                background-color: #333333;
                p {
                    font-weight:300;
                    font-size: $font-size-22;
                    color: $color-white;
                    text-align: center;
                    margin-bottom: 0;
                    font-family: $font-family-1;
                }
            }
            &_details {
                z-index: 3;
                font-weight:300;
                background-color: #be1e2d;
                a {
                    p {
                      color: $color-white;
                      text-align: center;
                      font-weight:300;
                      font-size: $font-size-22;
                      margin-bottom: 0;
                      font-family: $font-family-1;
                      color: $color-white;
                      transition: .2s;
                    }
                    &:hover {
                        p {
                          color:$color-black;
                          transform: translateY(1px);
                          transition: .2s;
                          font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

.popup {
    &__title {
        background-color: $color-1;
        padding: 15px;
        margin-bottom: 5px;
        h3 {
            color: $color-white;
        }
    }
    &__content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        & > div {
            width: 50%;
        }

        &_images {
            display: flex;
            flex-wrap: wrap;
            &_main-image {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            &-thumb {
                width: 33%;
                min-width: 100px;
                margin: 3px 1px;
            }
        }

        &_text {
            padding: 20px;
            h3.prix {
                color: $color-1;
                font-size: $font-size-40;
                margin-bottom: 20px;
            }
            h3,h4,h2,h1,h5,h6 {
                color: $color-black;
                font-size: $font-size-24;
                font-weight: 500;
            }
            p, li {
              color: $color-black;
              font-size: $font-size-20;
              line-height: 1.2;
              font-weight: 400;
            }
        }
        &_btn-wrapper {

            .btn {width: 66%;}
            .div-pdf {
                position: relative;
                .overlay {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      opacity: 0;
                      visibility: hidden;
                      transition: .3s;
                }
                &:hover {
                    .overlay {
                        visibility: visible;
                        opacity: 1;
                        transition: .3s;
                    }
                }
            }
        }
        @media screen and (max-width: $size-xs-max){
            & > div {
                width: 100%;
            }
        }
    }
}
.mfp-iframe-holder .mfp-content {
    @media screen and (max-width: $size-xs-max){
      height: 90vh;
    }
}
